<template>
    <div class="template-wrapper">
        <div class="template-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/admin/dataTemplateManager' }">数据分析管理</el-breadcrumb-item>
                <el-breadcrumb-item>数据模板管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div>
                <el-button class="btn-blue" @click="sendBtn" style="display: inline-block;">发送</el-button>
                <el-button class="btn-blue" @click="addCompetition" style="display: inline-block;">添加</el-button>
            </div>
        </div>
        <el-table ref="multipleTable" :data="dataTemplateList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}"
                   v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading">
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column prop="template_name" align="left" label="数据模板名称" :resizable="false"></el-table-column>
            <el-table-column label="操作" width="300" align="center" :resizable="false">
                <template slot-scope="scope">
                    <!-- <el-button type="text" style="color: #1122D8" @click="editBtn(scope.row)">编辑</el-button>
                    <el-button type="text" style="color: #FF0000" @click="deleteBtn(scope.row.data_template_id)">删除</el-button> -->
                    <el-link :underline="false" class="link-blue" @click="copyBtn(scope.row.data_template_id)">复制</el-link>
                    <el-link style="margin: 0 10px" :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="deleteBtn(scope.row.data_template_id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="templatePages.currentPageNum"
                       :page-size="templatePages.pageSize"
                       :total="templatePages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="templateListCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { getDataTemplateListAPI, delDataTemplateAPI, copyDataTemplateAPI, sendDataTemplate } from "@/utils/apis"
    export default {
        name: "dataTemManagement",
        data() {
            return {
                //模板列表
                dataTemplateList: [],
                //分页
                templatePages: {
                    currentPageNum: 1,
                    pageSize: 10,
                    total: 0,
                },
                loading: false,
            }
        },
        mounted() {
            this.getDataTemplateList();
        },
        methods: {
            async sendBtn(){
                let id =this.$refs.multipleTable.selection.map((item) => item.data_template_id);
                if(id && id.length > 0){
                  const result = await this.$common.sendData(sendDataTemplate, { data_template_ids: id });
                  result && this.getDataTemplateList();
                }else{
                  this.$message.error("请勾选需要发送的数据！")
                }
            },
            //获取模板列表
            async getDataTemplateList() {
                try {
                    this.loading = true
                    let param = {
                        paging: '1',
                        page: this.templatePages.currentPageNum,
                        pageSize: this.templatePages.pageSize
                    }
                    let { data } = await getDataTemplateListAPI(param)
                    this.dataTemplateList = data.list;
                    this.templatePages.total = data.total
                    this.loading = false
                } catch (e) {
                    this.$message(e.msg)
                }
            },
            async copyDataTemplate(id) {
                    let params = {
                        data_template_id: id
                    }
                    await copyDataTemplateAPI(params).then((res) => {
                        res.code < 400? this.$message.success(res.msg) : this.$message.error(res.msg);
                        this.getDataTemplateList()
                    }).catch((err) => {
                        this.$message.error(err.msg);
                    })
            },
            // 删除模板
            async delDataTemplate(id) {
                try {
                    let params = {
                        data_template_id: id
                    }
                    let res = await delDataTemplateAPI(params)
                    res.code < 400? this.$message.success(res.msg) : this.$message.error(res.msg);
                    this.getDataTemplateList()
                } catch (e) {
                    this.$message.error(e.msg)
                }
            },
            //创建数据模板
            addCompetition() {
                this.$router.push('/admin/dataTemplateManager/create')
            },
            //分页
            templateListCurrentChange(val) {
                this.templatePages.currentPageNum = val;
                this.getDataTemplateList();
            },
            //编辑
            editBtn(row) {
                // console.log(row)
                this.$router.push({
                    path: '/admin/dataTemplateManager/create',
                    query: {
                        id: row.data_template_id
                    }
                })
            },
            copyBtn(id) {
                this.$confirm('是否复制一份数据模板？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                })
                    .then(() => {
                        this.copyDataTemplate(id)
                    })
                    .catch(() => {
                        this.$message.info('已取消复制');
                    });
            },
            // 删除
            deleteBtn(id) {
                this.$confirm('是否删除该模板，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                })
                    .then(() => {
                        this.delDataTemplate(id)
                    })
                    .catch(() => {
                        this.$message.info('已取消删除');
                    });
            }
        }
    }
</script>

<style scoped lang="scss">
    .template-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .template-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
</style>